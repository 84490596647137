class FilterService {
  parseFilter(filter: any): string {
    let out = "";
    if (filter != null) {
      for (let i = 0; i < Object.keys(filter).length; i++) {
        const key = Object.keys(filter)[i];
        if (filter[key] != null) {
          out += `filter[${key}]=${filter[key]}`;
        }

        // Add & if there more values
        if (Object.keys(filter).length - i !== 1 && filter[key] != null) {
          out += "&";
        }
      }

      if (out[out.length - 1] === "&") {
        out = out.slice(0, -1);
      }
    }
    return out;
  }
}

export default new FilterService();
