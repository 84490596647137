import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "alert-heading" }
const _hoisted_3 = { class: "d-flex align-items-center h-100" }
const _hoisted_4 = {
  key: 0,
  class: "flex-shrink-0 ms-2"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([['alert-' + _ctx.type, _ctx.classes], "alert"]),
    style: {"border-radius":"5px"},
    role: "alert"
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi", [_ctx.icon]]),
              style: {"font-size":"20px"}
            }, null, 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex-grow-1", { 'ms-3': _ctx.icon }])
      }, [
        (_ctx.html)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.html
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          style: {"font-size":"14px","white-space":"pre"},
          class: _normalizeClass({ 'button-link-home-page': _ctx.buttonLink })
        }, [
          (!_ctx.html)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ])
  ], 2))
}