
import { defineComponent } from "vue";

export default defineComponent({
  name: "FileLink",
  props: {
    type: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    html: {
      type: String,
      default: null,
    },
    icon: {
      default: null,
    },
    buttonLink: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    return {};
  },
});
